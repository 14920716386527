@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: local('ITC Avant Garde Gothic Std'),
    url(../assets/fonts/ITCAvantGardeStd-Md.woff2) format('woff2'),
    url(../assets/fonts/ITCAvantGardeStd-Md.woff) format('woff');
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std XLt';
  src: local('ITC Avant Garde Gothic Std XLt'),
    url(../assets/fonts/ITCAvantGardeStd-XLt.woff2) format('woff2'),
    url(../assets/fonts/ITCAvantGardeStd-XLt.woff) format('woff');
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std Bk';
  src: local('ITC Avant Garde Gothic Std Bk'),
    url(../assets/fonts/ITCAvantGardeStd-Bk.woff2) format('woff2'),
    url(../assets/fonts/ITCAvantGardeStd-Bk.woff) format('woff');
}
